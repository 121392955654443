import {ConstrainValues} from "./constrain_value";
import {setVisibleConstraint} from "./functions_constrain_set";

//Constrain values for colors which don't require anything special
export const constrainGenericColor = (constrainValues: ConstrainValues) =>{
    //Grid Type
    let constrain_type = constrainValues.grid_type;
    setVisibleConstraint(constrain_type, "Two Tone", true);

    constrain_type = constrainValues.glass;
    setVisibleConstraint(constrain_type, "I89", true);
    setVisibleConstraint(constrain_type, "Ultra Low-E", true);
}

//Constrain values based on frame color black capstock
export const constrainBlackCap = (constrainValues: ConstrainValues) =>{
    //Grid Type
    let constrain_type = constrainValues.grid_type;
    setVisibleConstraint(constrain_type, "Contour DVL", true);
    setVisibleConstraint(constrain_type, "Flat DVL", true);   
}