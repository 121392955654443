import {useRef, useEffect} from "react";
import {RenderData} from "../render_data/render_data";
import {MousePosition} from "./input_handler";
import {handleClick} from "./functions_viewport";
import {Render} from "./viewport_render";

interface CanvasProps{
    setRenderData: React.Dispatch<React.SetStateAction<RenderData>>
    renderData: RenderData    
}

const ControlCanvas = ({
    setRenderData, 
    renderData,    
}: CanvasProps) =>{
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [coords, handleCoords] = MousePosition(true);

    const handleMouseClick = (x: number, y: number) =>{
        handleClick(renderData, setRenderData, x, y); //Interpret the mouse click        
    }

    //Monitor for changes which initiate a render
    useEffect(() =>{       
        if(renderData.render_frame && renderData.viewport.context){ //If a frame needs to be rendered            
            //Render the frame and then flip the rendering flag back to false
            if(renderData.viewport.width >0 && renderData.viewport.height >0){
                Render(renderData);
            }
            
            
            setRenderData((render)=>({
                ...render,
                render_frame: false
            }));
        }
        else{
            if(renderData.viewport.context === null){ //If a context has not been set yet
                const canvasElement = canvas.current;
                if(!canvasElement) return; //*********** Should probably have this throw some sort of error
                
                //Set the context from the canvas into the renderData.viewport object
                const context = canvasElement.getContext("2d");

                //Set the context in the render data object. 
                //****** May also want to have this throw an error if context does not exist
                if(context){            
                    setRenderData((render) => ({
                        ...render,
                        viewport: {
                            ...render.viewport,
                            context: context,
                        },
                    }))
                }
            }

        }
    }, [renderData.render_frame, renderData, setRenderData, renderData.viewport.context]);    

    return (        
        <canvas
            ref={canvas}
            width={renderData.viewport.width}
            height={renderData.viewport.height}
            //style={{border: renderData.viewport.border_size}}

            onClick={(event) =>{
                handleCoords((event as unknown) as MouseEvent);                
                if(canvas.current){                    
                    handleMouseClick(coords.x, coords.y);
                }
            }}
        />
    );
}

export default ControlCanvas;