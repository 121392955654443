
export enum LimitValue {
    MIN_ANGLE = 40,
    MIN_SIDE_LENGTH = 6,
    MIN_CIRCLE_RADIUS = 23.5,
    MIN_VENT_LENGTH = 3,
    MAX_WINDOW_XOP = 4,
    MAX_WINDOW_STACK = 3,
    MIN_WINDOW_WIDTH = 10,
    MIN_WINDOW_HEIGHT = 10,
    MIN_DOOR_WIDTH = 46,
    MIN_DOOR_HEIGHT = 78
}

export interface Limit{
    min_angle: number;    
    min_side_length: number;
    min_circle_radius: number;
    min_vent_length: number;
}

export const initLimit = (): Limit =>({
    min_angle: LimitValue.MIN_ANGLE,    
    min_side_length: LimitValue.MIN_SIDE_LENGTH,
    min_circle_radius: LimitValue.MIN_CIRCLE_RADIUS,
    min_vent_length: LimitValue.MIN_VENT_LENGTH
})