import {RenderData} from "../render/render_components/render_data/render_data";
import {WindowFragment, Line, initLine} from "../render/render_components/window/window_fragment";
import {Segment} from "../render/render_components/render_data/enum_render";
import {initErrorPostLine} from "../render/render_components/render_data/error_data";

//Check if the window posts are valid
export const validatePosts = (renderData: RenderData) =>{           
    //Reset any post errors
    renderData.error.post.model = [];
    
    //Iterate through all the windows
    renderData.window.forEach(source_window =>{        
        //Iterate through all the source model lines
        source_window.model.line.forEach(source_line =>{
            //If it's not a line on the edge of the viewport
            if(!isEdgeLine(source_line, renderData)){
                findOverlaps(source_line, source_window, renderData, false);    
            }
        })        

        //**************** Keep this. It checks window posts, not mulls. In case they change their mind again.
        /*
        //Iterate through all the posts
        source_window.post.forEach(post =>{
            const post_line = getPostLine(post, source_window);
            findOverlaps(post_line, source_window, renderData, true);
        })
        */
    })
}

//Find any lines or posts that overlap ends with the source window line
const findOverlaps = (source_line: Line, source_window: WindowFragment, renderData: RenderData, is_post: boolean) =>{
    let overlap_point_1 = 0;
    let overlap_point_2 = 0;
    let overlap_post = false;

    //Iterate through all the windows
    renderData.window.forEach(check_window =>{
        if(source_window.id !== check_window.id){
            //Iterate througuh all the check window model lines
            check_window.model.line.forEach(check_line =>{            
                //Check if there is an overlapping point and iterate the correct counter for that point
                if(isOverlapPoint(source_line.x1, source_line.y1, check_line.x1, check_line.y1)) overlap_point_1 ++;
                if(isOverlapPoint(source_line.x1, source_line.y1, check_line.x2, check_line.y2)) overlap_point_1 ++;
                if(isOverlapPoint(source_line.x2, source_line.y2, check_line.x1, check_line.y1)) overlap_point_2 ++;
                if(isOverlapPoint(source_line.x2, source_line.y2, check_line.x2, check_line.y2)) overlap_point_2 ++;
            })
        
            //Iterate through all the posts in the window
            check_window.post.forEach(post =>{
                const post_line = getPostLine(post, check_window);
                if(isOverlapPoint(source_line.x1, source_line.y1, post_line.x1, post_line.y1)) overlap_post = true;                
                if(isOverlapPoint(source_line.x1, source_line.y1, post_line.x2, post_line.y2)) overlap_post = true;                
                if(isOverlapPoint(source_line.x2, source_line.y2, post_line.x1, post_line.y1)) overlap_post = true;                
                if(isOverlapPoint(source_line.x2, source_line.y2, post_line.x2, post_line.y2)) overlap_post = true;                
            })        
        }        
    })

    //If one of the window points has more than 2 overlaps, or there was any overlap with the post points
    //Window corners always have 2 identical points to construct the square
    if(overlap_point_1 > 2 || overlap_point_2 > 2 || overlap_post){        
        
        const error_post = initErrorPostLine();
        error_post.is_post = is_post;
        error_post.line = source_line;

        renderData.error.post.model[renderData.error.post.model.length] = error_post;
    }
}

//If two points overlap: this is just to make the code a little more readable
const isOverlapPoint = (x1: number, y1: number, x2: number, y2: number): boolean =>{
    //******** This could be converted to inches at some point if necessary; right now it's mostly to account for decimal rounding
    const deviation = 5; //How far in pixels the points can be before it's considered overlapping    
    const x = Math.abs(x1 - x2);
    const y = Math.abs(y1 - y2);
    
    if(x <= deviation && y <= deviation) return true;
    return false;
}

//Convert a point to a testable line and return it
const getPostLine = (post: Line, window: WindowFragment): Line =>{
    const post_line: Line = initLine();

    //Check if the line is horizontal
    if(post.y1 - post.y2 === 0){
        post_line.x1 = window.model.line[Segment.LEFT].x1;
        post_line.x2 = window.model.line[Segment.RIGHT].x1;
        post_line.y1 = post.y1;
        post_line.y2 = post.y2;
    }
    else{
        post_line.x1 = post.x1;
        post_line.x2 = post.x2;
        post_line.y1 = window.model.line[Segment.TOP].y1;
        post_line.y2 = window.model.line[Segment.BOTTOM].y1;
    }

    return post_line;
}

//Check if the line is on the edge of the window
const isEdgeLine = (line: Line, renderData: RenderData): boolean =>{
    const top = 0;
    const bottom = renderData.viewport.height;
    const left = 0;
    const right = renderData.viewport.width;
    
    let is_edge = false;
    
    //If it's on the top edge
    if(line.y1 === top && line.y2 === top){
        is_edge = true;
    }
    //If it's on the bottom edge
    if(line.y1 === bottom && line.y2 === bottom){
        is_edge = true;
    }
    //If it's on the left edge
    if(line.x1 === left && line.x2 === left){
        is_edge = true;
    }
    //If it's on the right edge
    if(line.x1 === right && line.x2 === right){
        is_edge = true;
    }

    return is_edge;
}