import {
    ConstrainValues 
} from "./constrain_value";
import {
    constrainWindow, 
    constrainPatioDoor,
    constrainStack,
    constrainXOP
} from "./layout_constrain";
import {
    constrainSMF, 
    constrainTMF, 
    constrainMF2,
    constrainMF2Door,
    constrainVinylDoor
} from "./product_line_constrain";
import {RenderData} from "../render/render_components/render_data/render_data";
import {constrainBlackCap, constrainGenericColor} from "./frame_color_constrain";
import {constrainAdvanced, constrainSplit, constrainXOPDimension} from "./xop_properties_constraint";
import {constrainSelect} from "./select_constrain";
import {
    constrainPanelHorizontal, 
    constrainPanelVertical,
    constrainStraightNoGrid,
    constrainStraightGrid,
    constrainGridOnly,
    constrainWindowOnly,
    disableAllPattern,
    enablePatternStandard,
    enablePatternArch,
    enablePatternTriangle    
} from "./product_type_constrain";
import {constrainOverallDimension} from "./window_size_constraints";
import {validateWindows} from "./functions_validate_window";
import {
    setControl, 
    resetWindowControls,
    resetAllConstraints,     
    resetVisibleConstrainType
} from "./functions_constrain_set";

//Constrain values, based on the overall size 
export const constrainOverallSize = (renderData: RenderData, constrainValues: ConstrainValues) =>{
    constrainOverallDimension(renderData, constrainValues);
}

//Constrain values, based on window and or panel selections
export const constrainActiveWindow = (renderData: RenderData, constrainValues: ConstrainValues) =>{    
    constrainSelect(renderData, constrainValues);
}

//Constrain values, based on product type
export const constrainProductType = (description: string, constrainValues: ConstrainValues, renderData: RenderData) =>{
    resetWindowControls(constrainValues);        
    
    //Set the default state for controls
    const control = constrainValues.control;
    setControl(control.window, false, false);
    setControl(control.grid, true, true);
    setControl(control.screen, true, true);
    //Set the default state for grid patterns
    disableAllPattern(constrainValues);    
    
    switch(description){
        case "XO":
        case "OX":
        case "XOO":
        case "OXO":
        case "OOX":
        case "XOX":
        case "OXXO":
            constrainPanelHorizontal(constrainValues);
            enablePatternStandard(constrainValues);            
        break;
        case "Single Hung":
            constrainPanelVertical(constrainValues);
            enablePatternStandard(constrainValues);            
        break;
        case "Peak":
        case "L Rake":     
        case "R Rake":
            constrainStraightNoGrid(constrainValues);
            enablePatternTriangle(constrainValues); //***** Note: No patterns entered at this time; not sure if there needs to be
        break;
        case "Straight Leg Arch":
        case "L Straight Leg Arch":
        case "R Straight Leg Arch":            
            constrainStraightGrid(constrainValues);
            enablePatternArch(constrainValues);
        break;
        case "Factory Arch":
            constrainGridOnly(constrainValues);
            enablePatternArch(constrainValues);
        break;
        case "PW":
            constrainWindowOnly(constrainValues);
            enablePatternStandard(constrainValues);
        break;
        default:
            setControl(control.window, true, true);
        break;
    }       

    validateWindows(constrainValues, renderData);
}

//Constrain layouts according which button was selected (header button)
export const constrainLayout = (buttonName: string, constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;
    setControl(control.configurator, false, false);
    setControl(control.xop, true, true);
    setControl(control.window, true, true);
    
    constrainValues.control.layout = buttonName;    
    
    resetAllConstraints(constrainValues);
    switch (buttonName){
        case "Patio Door":
            constrainPatioDoor(constrainValues);
        break;
        case "Stack/Mull":
            constrainStack(constrainValues);
        break;
        case "XOP":
            constrainXOP(constrainValues);
        break;
        case "Window":            
            constrainWindow(constrainValues);
        break;
    }
}

//Constrain values, based on product line selections
export const constrainProductLine = (description: string, constrainValues: ConstrainValues, renderData: RenderData) =>{        
    
    resetVisibleConstrainType(constrainValues.frame_color);
    resetVisibleConstrainType(constrainValues.frame_type);

    switch (description){
        case "Vinyl Door":
            constrainVinylDoor(constrainValues, renderData);
        break;
        case "Aluminum Door":
            constrainMF2Door(constrainValues, renderData);
        break;
        case "SMF Vinyl":
            constrainSMF(constrainValues);
        break;
        case "TMF Vinyl":
            constrainTMF(constrainValues);
        break;
        case "MF-2":
            constrainMF2(constrainValues);
        break;        
    }
}

//Constrain values, based on frame color selection
export const constrainFrameColor = (description: string, constrainValues: ConstrainValues) =>{
    resetVisibleConstrainType(constrainValues.grid_type);
    
    switch (description){
        case "Almond":
        case "Bronze":
        case "White":
            constrainGenericColor(constrainValues);
        break;
        case "Black Capstock":
            constrainBlackCap(constrainValues);
        break;        
    }
}

//Constrain values, based on grid selections
export const constrainGrid = (renderData: RenderData, description: string, constrainValues: ConstrainValues) =>{
    switch(description){
        case "No Grid":
            setControl(constrainValues.control.grid_dvl, true, true);
        break;
        case "Standard":
            setControl(constrainValues.control.grid_dvl, false, false);
        break;
        case "Margin":        
        case "Sunburst":        
        case "Diagram":
            setControl(constrainValues.control.grid_dvl, true, true);            
        break;        
    }

    const comment = renderData.warning.comment_grid;
    let commentAdded = false;
    //Iterate over the windows    
    renderData.window.forEach(window =>{
        //Iterate over the panels and check if any of of the grids need a comment
        window.panel.forEach(panel =>{
            switch(panel.data.grid.grid_pattern.value){
                case "Margin":
                case "Sunburst":
                case "Diagram":
                    comment.description = "Describe grid pattern in comments.";
                    commentAdded = true;
                break;
            }
        })
    })
    if(!commentAdded){ //If a comment wasn't added, clear the grid comment in case anything was in there
        renderData.warning.comment_grid.description = "";
    }

}

export const constrainXOPProperties = (
    description: string, 
    value: string, 
    constrainValues: ConstrainValues, 
    renderData: RenderData
) =>{
    switch (description){
        case "Advanced":
            constrainAdvanced(value, constrainValues);
        break;
        case "split_horizontal":
            constrainSplit(renderData, constrainValues);
        break;
        case "split_vertical":
            constrainSplit(renderData, constrainValues);
        break;
        case "input_top":
        case "input_bottom":
        case "input_left":
        case "input_right":
        case "input_width":
        case "input_height":
            constrainXOPDimension(constrainValues, renderData);
        break;
    }
}





