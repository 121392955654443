import {RenderData} from "../../render/render_components/render_data/render_data";
import {FrameColor} from "../../render/render_components/render_data/enum_render";
import {setFrameColor} from "../../render/render_components/viewport/functions_viewport";

//Reset Frame Type
export const resetFrameType = (renderData: RenderData) =>{
    renderData.data.frame_type.id = 0;
    renderData.data.frame_type.value = "";
    resetFrameColor(renderData);
}

//Reset Frame Color
export const resetFrameColor = (renderData: RenderData) =>{
    setFrameColor(renderData, FrameColor.DEFAULT);
    renderData.data.frame_color.value = "";
    renderData.data.frame_color.id = 0;    
}



//**********************************************************************
//All the functions below are currently being handled through other means
//Note, I didn't finish out the resetGlass function or resetProductType
/*
//Reset Product Type
//Reset Product Line
export const resetProductLine = (renderData: RenderData) =>{
    resetFrameType(renderData);
}
export const resetProductType = (renderData: RenderData) =>{
    
}

//Reset Glass
const resetGlass = (renderData: RenderData) =>{
    renderData.window.forEach(window =>{
        window.panel.forEach(panel =>{
            
        })
    })
}

//Reset Grids
const resetGrid = (renderData: RenderData) =>{
    renderData.window.forEach(window =>{
        window.panel.forEach(panel =>{
            clearPanelGrid(renderData, panel);
            panel.data.grid.grid_type = initDataPoint("Flat DVL");
            panel.data.grid.has_grid = false;
            panel.data.grid.grid_pattern = initDataPoint("No Grid");
        })
    })
}

//Reset Screen
const resetScreen = (renderData: RenderData) =>{
    renderData.window.forEach(window =>{
        window.panel.forEach(panel =>{
            panel.data.has_screen = false;
            
        })
    })
}

//Reset Option
const restOption = (renderData: RenderData) =>{
    renderData.window.forEach(window =>{
        window.panel.forEach(panel =>{
            panel.data.option.clear();            
        })
    })
}
*/