import {
    ConstrainValues, 
    ConstrainType, 
    ConstrainControlProperty,
    ConstrainParameter
} from "./constrain_value";

//Reset all the constraints to their original values
export const resetAllConstraints = (constrainValues: ConstrainValues) =>{
    resetConstrainType(constrainValues.product_line);
    resetConstrainType(constrainValues.frame_type);
    resetConstrainType(constrainValues.frame_color);
    resetConstrainType(constrainValues.product_type);
    resetConstrainType(constrainValues.glass);
    resetConstrainType(constrainValues.grid_type);   
    resetConstrainType(constrainValues.screen);
    resetConstrainType(constrainValues.option);

    resetAllControlConstraints(constrainValues);
}

//Reset all constraints for a specific type of constrain
export const resetConstrainType = (constrainType: ConstrainType) =>{    
    //Iterate through all the parameters in the constrain type
    constrainType.parameter.forEach(parameter =>{
        if(!parameter.is_removed){
            parameter.is_disabled = false;
            parameter.is_hidden = false;
        }
    })
}

//Reset constraints for visible constrain types
export const resetVisibleConstrainType = (constrainType: ConstrainType) =>{
    //Iterate through all the parameters in the constrain type and set only visible constraints
    constrainType.parameter.forEach(parameter =>{
        if(!parameter.is_removed){
            if(!parameter.is_hidden){
                parameter.is_disabled = false;
            }
        }
    })
}

//Set the constraints for a parameter
export const setConstraint = (constrainType: ConstrainType, description: string, is_disabled: boolean, is_hidden: boolean) =>{    
    const parameter = getConstraint(constrainType, description);

    //Set if a parameter is hidden or not    
    parameter.is_disabled = is_disabled;
    parameter.is_hidden = is_hidden;
    
    if(parameter.is_removed){
        parameter.is_disabled = true;
        parameter.is_hidden = true;
    }
}

//Set a control's enable, only if it is visible
export const setVisibleConstraint = (constrainType: ConstrainType, description: string, is_disabled: boolean) =>{
    const parameter = getConstraint(constrainType, description);
    
    if(!parameter.is_hidden){
        parameter.is_disabled = is_disabled;
    }
    if(parameter.is_removed){
        parameter.is_hidden = true;
        parameter.is_disabled = true;
    }
}

//Get a constrain values based on its description and return it
export const getConstraint = (constrainType: ConstrainType, description: string): ConstrainParameter =>{
    const id: number = constrainType.string_id.get(description)!;    

    return constrainType.parameter.get(id)!;
}

//Set the constraints for visible controls
export const setVisibleControl = (control_type: ConstrainControlProperty, is_disabled: boolean) =>{
    if(!control_type.is_hidden){        
        control_type.is_disabled = is_disabled;
    }
}

//Set the constrains for a control
export const setControl = (control_type: ConstrainControlProperty, is_disabled: boolean, is_hidden: boolean) =>{
    control_type.is_disabled = is_disabled;
    control_type.is_hidden = is_hidden;
}

//Reset all the constrains for controls
export const resetAllControlConstraints = (constrainValues: ConstrainValues) =>{
    resetXOPControls(constrainValues);
    resetWindowControls(constrainValues);
}

//Reset window property control constraints
export const resetWindowControls = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;

    setControl(control.window_width, true, false);
    setControl(control.window_height, true, false);
    setControl(control.window_open_direction, true, false);
    setControl(control.window_second_height, true, false);
}

//Reset XOP constrol constraints
const resetXOPControls = (constrainValues: ConstrainValues) =>{
    const control = constrainValues.control;
    
    setControl(control.xop_advanced, true, true);
    setControl(control.xop_width, true, true);
    setControl(control.xop_height, true, true);
    setControl(control.xop_top, true, true);
    setControl(control.xop_bottom, true, true);
    setControl(control.xop_left, true, true);
    setControl(control.xop_right, true, true);
}