import {Line, initLine} from "../window/window_fragment";
import {VertexPoint} from "../model/vertex_point";
import {LimitValue} from "../../../constraints/limit";

export interface ErrorPoint{
    line_width: number;
    color: string;
    post: ErrorPost;
    side: ErrorSide;
    angle: ErrorAngle;
    vent_size: ErrorVentSize;    
    config: ErrorConfig;
}
export interface WarningPoint{
    color: string
    comment_grid: CommentGrid;
}

//Init the errors
export const initErrorPoint =(): ErrorPoint =>({
    line_width: 2,
    color: "rgba(200, 0, 0, 1)",
    post: initErrorPost(),
    side: initErrorSide(),
    angle: initErrorAngle(),
    vent_size: initErrorVentSize(),
    config: initErrorConfig()
})

//Init the warnings
export const initWarningPoint = (): WarningPoint =>({
    color: "rgba(256,256,256,1)",    
    comment_grid: initCommentGrid()
})

//Get an error string based on existing errors
export const getErrorString =(error: ErrorPoint): string[] =>{
    
    const error_string: string[] = [];    

    //Minimum angle
    if(error.angle.vertex.length >0){
        addError(error.angle.description, error_string);
    }
    
    //Inline posts
    if(error.post.model.length >0){
        addError(error.post.description, error_string);
    }

    //Minimum side length
    if(error.side.line.length >0){
        addError(error.side.description, error_string);
    }

    //Minimum vent length
    if(error.vent_size.line.length >0){
        addError(error.vent_size.description, error_string);
    }    

    //Invalid size or configuration    
    if(error.config.display){        
        addError(error.config.description, error_string);
    }

    return error_string;
}

export const getWarningString = (warning: WarningPoint): string[] =>{
    const warning_string: string[] = [];

    if(warning.comment_grid.description.length >0){
        addWarning(warning.comment_grid.description, warning_string);
    }

    return warning_string;
}

const addError = (error_value: string, error_string: string[]) =>{
    error_string[error_string.length] = error_value;
}

const addWarning = (warning_value: string, warning_string: string[]) =>{
    warning_string[warning_string.length] = warning_value;
}


interface ErrorPost{
    post_width: number;
    mull_width: number,
    model: ErrorPostLine[];
    description: string;
}

const initErrorPost =(): ErrorPost =>({
    post_width: 10,
    mull_width: 23,
    model: [],
    //description: "Posts and/or mulls cannot be in-line."
    description: "Mulls cannot be in-line."
})

interface ErrorPostLine{
    is_post: boolean;
    line: Line;    
}

export const initErrorPostLine = (): ErrorPostLine =>({
    is_post: false,
    line: initLine(),    
})

interface ErrorSide{
    line_width: number;
    line: Line[];
    description: string;    
}

const initErrorSide = (): ErrorSide =>({
    line_width: 10,
    line: [],
    description: "Length cannot be less than " + LimitValue.MIN_SIDE_LENGTH + " inches."

})

interface ErrorAngle{
    line_width: number;
    radius: number;
    vertex: VertexPoint[];
    description: string;
}

interface ErrorVentSize{    
    line_width: number,
    line: Line[];
    description: string;
}

const initErrorVentSize = (): ErrorVentSize =>({
    line_width: 5,
    line: [],
    description: "Vent length cannot be less than " + LimitValue.MIN_VENT_LENGTH + " inches."
})

interface CommentGrid{
    description: string;
}

const initCommentGrid = (): CommentGrid =>({
    description: ""
})

const initErrorAngle = (): ErrorAngle =>({
    line_width: 5,
    radius: 15,
    vertex: [],
    description: "Angle cannot be less than " + LimitValue.MIN_ANGLE + " degrees."
})

interface ErrorConfig{
    display: boolean;
    description: string;
}

const initErrorConfig = () =>({
    display: false,
    description: "Invalid configuration and or size."
})