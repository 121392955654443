import {ConstrainValues} from "./constrain_value";
import {RenderData} from "../render/render_components/render_data/render_data";
import {constrainProductType} from "../constraints/functions_constrain";
import {resetVisibleConstrainType} from "./functions_constrain_set";
import {disableShapeWindow} from "./functions_shape_constrain";
import {constrainXOPControls, resetXOPControls} from "./xop_properties_constraint";
import {limitCircle} from "./functions_limit";


//Constrain values, based on selections in the viewport
export const constrainSelect = (renderData: RenderData, constrainValues: ConstrainValues) =>{
      
    const window = renderData.window[renderData.active_fragment_id];
    
    resetVisibleConstrainType(constrainValues.product_type);
    resetXOPControls(constrainValues);
    
    //Apply constraints    
    constrainProductType(window.data.product_type.value, constrainValues, renderData);        
    disableShapeWindow(window, renderData, constrainValues);    
    constrainXOPControls(window, renderData, constrainValues);

    //Constrain circles based on window size
    if(renderData.window.length === 1){
        limitCircle(constrainValues, renderData);
    }        
}