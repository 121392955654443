import {ConstrainValues} from "./constrain_value";
import {RenderData} from "../render/render_components/render_data/render_data";
import {setVisibleConstraint} from "./functions_constrain_set";
import {canArc, canCircle, isLeft, isRight, isTop} from "./functions_validate_window";
import {WindowFragment} from "../render/render_components/window/window_fragment";

//Constrain windows with a shape
export const disableShapeWindow = (window: WindowFragment, renderData: RenderData, constrainValues: ConstrainValues) =>{
    
    //If the window doesn't border the left edge
    if(!isLeft(window)){
        disableLeftShapes(constrainValues);
    }
    //If the window doesn't border the right edge
    if(!isRight(window, renderData)){
        disableRightShapes(constrainValues);
    }
    //If the window doesn't border the top edge
    if(!isTop(window)){
        disableLeftShapes(constrainValues);
        disableRightShapes(constrainValues);
        disableTopShapes(constrainValues);
    }
    
    const constrain_type = constrainValues.product_type;
    
    if(!canArc(window)){
        setVisibleConstraint(constrain_type, "Factory Arch", true);        
    }
    
    if(!canCircle(window, renderData)){
        setVisibleConstraint(constrain_type, "Circle", true);
    }
}

//Disable windows with a top shape
const disableTopShapes = (constrainValues: ConstrainValues) =>{
    const constrain_type = constrainValues.product_type;

    setVisibleConstraint(constrain_type, "Factory Arch", true);
    setVisibleConstraint(constrain_type, "Peak", true);
    setVisibleConstraint(constrain_type, "Triangle", true);
}

//Disables windows with a left shape
const disableLeftShapes = (constrainValues: ConstrainValues) =>{
    const constrain_type = constrainValues.product_type;

    setVisibleConstraint(constrain_type, "L Straight Leg Arch", true);
    setVisibleConstraint(constrain_type, "L 1/4 Circle", true);
    setVisibleConstraint(constrain_type, "L Rake", true);
    setVisibleConstraint(constrain_type, "L Triangle", true);
}

//Dsiable windows with a right shape
const disableRightShapes = (constrainValues: ConstrainValues) =>{
    const constrain_type = constrainValues.product_type; 
    
    setVisibleConstraint(constrain_type, "R 1/4 Circle", true);
    setVisibleConstraint(constrain_type, "R Rake", true);
    setVisibleConstraint(constrain_type, "R Straight Leg Arch", true);
    setVisibleConstraint(constrain_type, "R Triangle", true);        
}