import { RenderData } from "../render/render_components/render_data/render_data"
import { ConstrainValues } from "./constrain_value"
import { setVisibleConstraint } from "./functions_constrain_set";

//Limit the ability to select a circle if it's below the radius threshold
export const limitCircle = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Just short handing some values
    const width = renderData.viewport.inch_width;
    const height = renderData.viewport.inch_height;

    //If the width or the height is less than the min diameter, then constrain it
    if(width < constrainValues.limit.min_circle_radius || height < constrainValues.limit.min_circle_radius){
        const constrain_type = constrainValues.product_type;
        setVisibleConstraint(constrain_type, "Circle", true);
        setVisibleConstraint(constrain_type, "Oval", true);
    }
}